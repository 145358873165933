<template>
    <main id="liste" v-cloak>
        <!-- A changer par le nom du tiers sur lequel on effectue les opérations de compta -->
        <HeaderTab :title="$t('horse.ajout_cheval')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "HorseAjoutBeta",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'searchSire',
                swiper_tabs: [],
                mask_ifce: false
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab

            this.mask_ifce = this.getConfigIfce()

            const tabs = [{
                id: 'addHorse',
                label: 'horse.ajout_unitaire',
                active: false,
                href : 'AddHorse'
            }];

            if(!this.mask_ifce) {
                tabs.push({
                    id: 'addHorseLot',
                    label: 'horse.ajout_lot',
                    active: false,
                    href : 'AddHorseLot'
                })
            }

            tabs.push({
                id: 'manual_add',
                label: 'horse.manual_add',
                active: false,
                href : 'AddManuelBeta'
            });

            this.swiper_tabs = tabs

            if(this.$route.name !== 'AddManuelBeta' && this.$route.name != this.swiper_tabs[0].href) {
                this.$router.push({name: this.swiper_tabs[0].href})
            }
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
